let strings = {
    en: {
        exploreOEA: "Explore OEA",
        news: "News",
        logout: "Logout",
        login: "Login",
        homeTitle: "Order of Engineers & Architects <br/> in Tripoli, Lebanon",
        footerCopyrights: "Order of Engineers & Architects in Tripoli - Copyright 2022",
        aboutOEA: "About OEA",
        boardOfMembers: "Board Members",
        organizationChart: "Organization Chart",
        branches: "Branches",
        associations: "Committees",
        trainings: "Trainings",
        events: "Events",
        gallery: "Gallery",
        lawsAndRegulations: "Laws & regulations",
        engZone: "Engineers & Architects Zone",
        contactUs: "Contact Us",
        contactOea: "Contact OEA",
        contactLaboratory: "Contact Laboratory",
        contactTrainingCenter: "Contact Training Center",
        trainingCenter: "Training Center",
        'training-Center': "Training Center",
        visitUs: "Visit Us",
        visitOea: "Visit OEA",
        visitLaboratory: "Visit Laboratory",
        visiTrainingCenter: "Visit Training Center",
        latestFeeds: "Latest News",
        aboutUs: "About Us",
        upcomingEvents: "Upcoming Events",
        privacyPolicy: "Privacy Policy",
        termOfUse: "Terms Of Use",
        services: "Services",
        shares: "Share",
        likes: "Likes",
        views: "Views",
        All: "All",
        all: "All",
        "Training-Center": "Training Center",
        "OEA-Support": " OEA Support",
        readMore: "Read More",
        dateStart: "Date Start",
        startingFrom: "Starting From:",
        dateEnd: "Date End",
        instructors: "Instructors",
        alreadyRegistered: "You Are Already Registered",
        viewRegistrationDetails: "View Registration Details",
        register: "Register",
        allDay: "All Day",
        start: "Start",
        end: "End",
        Announcements: "Announcements",
        Activities: "Activities",
        Mortality: "Mortality",
        discountsAndBenefits: "Discounts & Benefits",
        addCompany: "Add Company",
        companyName: "Company Name",
        companyLocation: "Location",
        uploadCompanyLocation: "Company Location",
        uploadCompanyLogo: "Company Logo",
        "Companies Directory": "Companies Directory",
        companyPhone: "Company Phone",
        companyEmail: "Company Email",
        companyWebsite: "Company Website",
        companyDescription: "Company Description",
        "Add Company": "Add Company",
        insurance: "Insurance",
        registrationTo: "Registration to",
        technicalDocuments: "Technical Documents",
        MyRegistrationFor: "My Registration For The",
        myRegistration: "My Registration",
        title: "Title",
        date: "Date",
        name: "Name",
        email: "Email",
        phoneNumber: "Phone Number",
        additionalInfo: "Additional Info",
        viewTrainingDetails: "Training Details",
        editRegistration: "Edit Registration",
        cancelRegistration: "Cancel Registration",
        engDirectory: "Engineers Directory",
        major: "Major",
        skills: "Skills",
        myComplaints: "My Suggestions",
        newComplaint: "New Suggestion",
        complaint: "Suggestion",
        complaintImage: "Suggestion Image",
        complaintVoice: "Suggestion Voice",
        complaintDescription: "Suggestion Description",
        complaintStatus: "Suggestion Status",
        "MY SUGGESTIONS": "MY SUGGESTIONS",
        "ADD SUGGESTION": "ADD SUGGESTION",
        recordYourComplaint: "Record Your Suggestion",
        uploadSuggestionImage: "Upload Suggestion image",
        uploadingVoice: "Uploading voice",
        complaintAdded: "Suggestion submitted",
        editComplaint: "Edit suggestion",
        edit: "Edit",
        voiceFailedToLoad: "Failed to load, retry",
        complaintDeleted: "Suggestion deleted",
        noComplaints: "You have no suggestions",
        initiated: "Initiated",
        share: "Share",
        underInvestigation: "Under investigation",
        closed: "Closed",
        sureToDeleteComplaint: "Are you sure you want to delete this suggestion?",
        errorUploadingVoice: "Error uploading voice",
        ciscoTrainings: "Cisco Trainings",
        calculator: "Calculator",
        "Registration Fees": "Registration Fees",
        "Engineer Fees": "Engineer Fees",
        back: "Back",
        calculatorResultNote: "This result is for guidance only. You need to contact the order to get the accurate fees",
        usdRate: "1 USD = 1500 LBP",
        engFees: "Engineer Fees",
        penaltyRate: "Penalty rate",
        penaltyAmount: "Penalty amount",
        numberOfLateYears: "Number of years since graduation",
        year: "Year(s)",
        registrationFees: "Affiliation fees",
        priceOfTerritory: "Cost of the project",
        annualFees: "Annual fees",
        nbyears: "Number of years",
        pickCalculationMethod: "Calculation Method",
        byArea: "By Area",
        byCost: "By Cost",
        projectTotal: "Project Surface",
        sqm: "Sqm",
        inputValue: "Input value",
        projectCategory: "Project category",
        projectSubCategory: "Project sub-category",
        priceValueRange: "Price value range",
        complexLevel: "Complex level",
        calculate: "Calculate",
        constructionTable: "Construction Table",
        pricePer1sqm: "Price per 1 sqm (as per table)",
        percentageOfEngineerFees: "Percentage of engineer fees",
        percentageOfEngFees: "Percentage of engineer fees (as per table)",
        primaryStudyFees: "Primary study fees APS",
        detailedStudyFees: "Detailed study fees APS",
        licenceFileFees: "License file fees",
        orderFees: "Order fees",
        orderFeesOutside: "Order fees - outside Tripoli",
        designFees: "Design and executive details fees",
        executiveDocumentFees: "Executive document fees",
        supervisingFees: "Supervising fees",
        constructionLicenseFees: "Construction license fees",
        executivePlansFees: "Executive Plans fees",
        result: "Result",
        select: "Select",
        simple: "Simple",
        normal: "Normal",
        luxurious: "Luxirious",
        complicated: "Complex",
        returnsToPartEng: "Returned back to the co-engineer 40% of the returns of OEA",
        electEngFees: "Payed to the electrical engineer from the returns of OEA",
        mechEngFees: "Payed to the mechanical engineer from the returns of OEA",
        quotaPercentagePartFees: "The percentage of the total fee of the participant (according to the quota distribution ratio)",
        elecPerc: "Percentage of the total fee of the electricity engineer",
        mechanicalPerc: "Percentage of the total fee of the Mechanical Engineer",
        remainingForMainEng: "Net Remaining For The Responsible Engineer Of OEA Returns",
        fundReceipts: "Total Value Of Receipts Payable To The Fund",
        pensionFund: "Pension Fund",
        reports: "Reports",
        payOnline: "Pay Online",
        polls: "Polls",
        jobs: "Jobs",
        position: "Position",
        deadline: "Deadline",
        qualifications: "Qualifications",
        jobDetails: "Details",
        contactInfo: "Contact info",
        profile: "My Profile",
        facebook: "Facebook",
        instagram: "Instagram",
        linkedIn: "Linkedin",
        gender: "Gender",
        male: "Male",
        skills: "Skills",
        searchSkill: "Search Skills",
        complaints: "Suggestions",
        engProfile: "Profile",
        cvUrl: "CV",
        female: "Female",
        uploadingPdf: "Uploading Pdf",
        errorUploadingPdf: "Error Uploading Pdf",
        isPublicProfile: "Visible In Engineers Directory",
        major: "Profession",
        twitter: "Twitter",
        userName: "Username",
        EngId: "Engineer ID",
        myRegistrations: "My Registrations",
        myComplaints: "My Suggestions",
        facebookAccount: "Facebook",
        twitterAccount: "Twitter",
        emailAddress: "Email",
        linkedinAccount: "Linkedin",
        instagramAccount: "Instagram",
        save: "Save",
        delete: "Delete",
        submit: "Submit",
        next: "Next",
        engineerId: "Engineer ID",
        firstName: "First Name",
        middleName: "Middle Name",
        lastName: "Last Name",
        countryCode: "Country Code",
        signin: "Sign In",
        username: "Username",
        resetPassword: "Reset Password",
        password: "Password",
        rememberMe: "Remember Me",
        joinOEA: " New Account",
        forgotPassword: "Forgot Password",
        enroll: "Enroll",
        changePhoneNumber: "Change Your Phone Number",
        unableToConnectToServer: "An Error Occured, Please Try Again Later",
        engineerId: "Engineer ID",
        firstName: "First Name",
        email: "Email",
        email2: "Email",
        phoneNumber: "Phone Number",
        allFieldMandatory: "All fields are mandatory",
        enrollThankYou: "Thank you for being interested in enrolling with OEA Tripoli",
        enrollDetailsSubmitted: "Your details has been successfully submitted.",
        enrollInfo: "We're now reviewing your information to check eligibility. You'll get an SMS to your phone number with a code within the next 24 hours.",
        enrollCode: "Input code you received by SMS",
        didntReceiveEnrollCode: "Didn't received your code?",
        resendCode: "Resend Code",
        correctPhone: "Correct Phone Number",
        welcomeInfo: "Welcome To OEA Tripoli Mobile App",
        createPasswordInfo: "Create Your Own Password",
        confirmPassword: "Confirm Password",
        goToSignIn: "Go To Sign In",
        iHaveACode: "You Have A Code To Activate The Account.",
        activateAccount: "Activate Account",
        enterEngId: "Please Enter Your Engineer Id",
        resetCodeReceived: "Already Received A Reset Code?",
        rememberedPassword: "Remembered Your Password?",
        pleaseChooseResetPasswordMethod: "Send Code by ",
        sms: "SMS",
        requestToJoin: "Request To Join",
        tryAgainError: "An Error Occured, Please Try Again Later",
        useCodeToLogin: "Please Use The Verification Code To Login",
        enterNewPass: "Enter New Password",
        confirmNewPass: "Confirm New Password",
        alreadySentRequest: "Already Sent A Request?",
        verifyEnrollement: "Verify Enrollment",
        alreadyHaveAccount: "Already Have An Account?",
        youMustBeLoggedIn: "You Must Sign In To Use This Feature",
        youMustBeLoggedInToSee: "Please Sign In To Take Advantage Of This Feature",
        confirmEnroll: "Confirm Enroll",
        signinSentence: "Sign In To Benefit From Engineers' Services Online",
        smsCode: "SMS Verification Code",
        retypePass: "Re-Type Password",
        facingProblem: "Facing a Problem?",
        sendNewRequest: "Send a New Enrollment Request",
        newUser: "New User?",
        joinOea: "Join OEA",
        forgotPass: "Forgot Password?",
        fullName: "Full Name",
        addPerson: "Add Person",
        removePerson: "Remove Person",
        comingSoon: "Coming Soon",
        pleaseWait: "Please Wait ...",
        "register-to-event": "Register To Event",
        "register-to-training": "Register To Training",
        Event: "Event",
        Training: "Training",
        ok: "Ok",
        home: "Home",
        enterPassword: "Enter New Password",
        eorder: "E-Order",
        introSentence: "To Carry Out Your Administrative And Financial Transactions, Please Click On The Following Link:",
        clickHere: "Click Here",
        renewJobDeclaration: "Renew Job Declaration",
        continueFeesPayment: "Continue Fees Payment",
        statementYear: "Professional Statement For The Year",
        generalInfo: "General Infos",
        engineerName: "Engineer Name",
        chapter: "Chapter",
        subChapter: "SubChapter",
        workType: "Work Type",
        workLocation: "Work Location",
        homeAddress: "Home Address",
        country: "Country",
        province: "Province",
        city: "City",
        district: "District",
        street: "Street",
        building: "Building",
        floor: "Floor",
        appartment: "Appartment",
        mobile: "Mobile",
        isForMailing: "(Is For Mailing)",
        workAddress: "Work Address",
        oops: "Oops! A problem occured while loading the page",
        tryAgain: "Click Here To Try Again",
        law: `Pursuant to the Article 8-9 of the rules of procedure:
            The engineer shall personally write down all the information contained in the professional statement belonging to him on his responsibility.
            And that any false information contained in the aforementioned statement exposes its owner to the suspension of transactions in the Syndicate
            in accordance with a decision issued by the Council of the Syndicate in this regard.
            It also subject him to disciplinary sanctions provided for by law.`,
        IAccept: "I Accept",
        clickToContinue: "Click Here To Continue",
        quotaLaw: "Only Consulting Engineers (Who Obtain A Quota) Are Authorized To Renew The Professional Statement At The Beginning Of The Year, ie On firstDate. As For The Other Engineers, Their Professional Data Is Renewed At The Beginning Of The Order Year, i.e. On secondDate.",
        visitOEA: `You Can't Change Your Position Online.<br/> To Change Anything In The Status Of "Consulting Engineers", <br/>You Must Come Personally To The Order Of Engineers. <br/><br/> You Can Contact The Order For More Information<br/> Via Phone Number : 06413110 <br/>Or<br/> Via E-mail : info@oea-Tripoli.org.lb`,
        statusPreviousYear: "The Status Of Your Professional Statement For The Previous Year Is:",
        changePosition: "Change Position",
        continueSamePosition: "Continue In The Same Position",
        delayVisitOea: `The Date Of Your Last Professional Statement Dates Back More Than A Year, So You Must Personally Come To The Order <br/><br/>Or Contact The Following Number: 06413110 <br/>Or Via E-mail: info@oea-Tripoli.org.lb`,
        everythingUpToDate: "Your Job Declaration is Up-to-date. Thank you. You Can Only View Your Last Job Declaration.",
        updatesText: `If There's Any Change, Please Edit Your Home And Work Address`,
        uploadDocuments: "Please Upload The Following Documents",
        noDocuments: "No Documents Required",
        remarks: "Remarks",
        responsibility: "The Information Received Is At Your Responsibility, And any False Information May Expose You To The Suspension Of Your Transactions And To Disciplinary Sanctions.",
        finish: "Finish Transation",
        workForEngineer: "Work For Engineer",
        workForEngineerID: "Work For Engineer ID",
        partnerEngineer: "Partner Engineer",
        partnerEngineerId: "Engineer's Partner ID",
        quotaForEngineer: "Quota For Engineer",
        quotaForEngineerID: "Quota Engineer ID",
        govInstitute: "Gov Institute",
        govInstituteID: "Gov Institute ID",
        govInstituteDesc: "Gov Institute Description",
        downloadFile: "To Download The Partnership Contract, Please Click Here.",
        downloadFileReceipt: "To Download The Receipt, Please Click Here",
        pictureNote: "Please note that you can only upload a picture or a pdf file with a maximum size of 1 MB.",
        bigFile: "Please upload a file less than 1MB",
        requestEnrollment: "Your Enrollment Request Is Under Review<br/><br/> We Will Verify Your Information then Send You A Verification Code On Your Mobile Number And Email To Be Able To Sign In",
        userDoesNotExists: "User ID not found",
        wrongPassword: "Incorrect Password",
        wrongData: "Incorrect Data",
        alreadyExists: "User Already Exists",
        doseNotExists: "User Does Not Exist",
        emailDoesNotExists: "Invalid Email",
        pendingReq: "Your Request Is Pending Approval",
        expiredCode: "The Verification Code Has Expired",
        requestDoesNotExist: "Request does not exist",
        codeUsed: "This Verification Code is Already Used",
        wrongCode: "Wrong Verification Code",
        confirmEnrollment: "Confirm Enrollment",
        yourCV: "Your CV",
        send: "Send",
        updateTypeLocationWork: "Please Modify Your Work Type And Location",
        requestExists: "You Have Already Sent A Request",
        engZoneSentence: ` The Engineers' Zone Offers Its Members a Customized Bouquet of Services and Features to Enhance Their Experience and Support Their Needs`,
        loginSentence: `Log In and Benefit From The OEA Engineers' Zone`,
        'Building License Transactions': 'Building License Transactions',
        'Restoration / Connection Permit / Demolition / Designation': 'Restoration / Connection Permit / Demolition / Designation',
        searchIn: 'Search in',
        registrationclosed: 'Registration Closed',
        visitTrainingCenter: 'Visit Training Center',
        noDataAvailable: 'No Data Available',
        wrongConfirmPassword: "Please Make Sure Your Passwords Match",
        wrongOldPassword: "Your Old Password Appears To Be Incorrect",
        changePassword: "Change Password",
        passwordChangedSuccessfully: "Your Password Has Been Changed Successfully. Use Your New Password To Login",
        personInfo: "Person Info",
        status: "Status",
        financialStatement: "Financial Statement",
        mandatoryUnpaidDues: "Mandatory unpaid dues",
        optionalUnpaidDues: "Unpaid dues",
        unpaidReceipt: "Unpaid Receipt",
        date: "Date",
        dueDate: "Due date",
        amount: "Amount",
        numberReceipt: "Receipt number",
        description: "Description",
        payReceiptB: "Please pay your unpaid receipts before issuing a new receipt",
        createdJobDeclaration: "Your administrative transactions have been completed",
        generatedReceipt: "Your financial transaction has been successfully completed",
        printReceipt: "Back to print receipt",
        noFinancialStatment: "You do not have any financial transactions",
        print: "Print",
        cantPrint: "This transaction is old, if it was paid, please send a copy to the following e-mail",
        skip: "Skip",
        batch: "batch",
        paymentTerms: "Payment terms",
        years: "The years",
        withoutInterest: "without interest",
        and: "and",
        feeAmount: "Fee Amount",
        interestAmount: "Interest Amount",
        pendingOperation: "You have a previous annual subscription transaction pending, you must contact the financial department",
        annualSubscription: "Annual subscription",
        fileCode: "File code",
        engineerNumber: "Engineer number",
        fileType: "File type",
        description: "Description",
        notes: "Notes",
        dateOfReceipt: "Date of receipt",
        deliveryDate: "Delivery date",
        currentStatusFile: "The current status of the file",
        lastUserHasFile: "The name of the last user or employee who has the file",
        licenseFileSentence: "To perform your technical transactions, Please Click On The Following Link:",
        licenseFile: "License Operation",
        enterFileCode: "Enter your operation code",
        extension: "Extension",
        employeeName: "Employee Name",

        //SEO Desription
        aboutOeaDescription: "About the order of engineers and architects in Tripoli",
        boardmembersDescription: "Board of members of the order of engineers and architects in Tripoli",
        branchesDescription: "Branches in the order of engineers and architects in Tripoli",
        committeesDescription: "The committees of the order of engineers and architects in Tripoli",
        organizationDescription: "The organiztaion chart of the order of engineers and architects in Tripoli",
        benefitsDescription: "Discounts and benefits offered by the Order of Engineers and Architects in Tripoli",
        companiesDescription: "Companies directory where you can find many companies with their details ",
        contactusDescription: "Contact the order of engineers and architects in Tripoli",
        insuranceDescription: "Insurance service offered by the order of engineers and architects in Tripoli",
        engineersDirectoryDescription: "Engineers directory where you can find a large number of engineers profiles with their contact info",
        jobDeclarationDescription: "Renew engineers job declaration online and in a fast way",
        eventsDescription: "List of all the events done by the order of engineers and architects in Tripoli",
        galleryDescription: "Photo gallery of the events and activities in collaboration with the order of engineers and architects in Tripoli",
        lawsDescription: "Laws and regulations of the order of engineers and architects in Tripoli",
        complaintsDescription: "Suggestions posted by the engineers",
        myregistrationsDescription: "My registrations to the events and trainings in the order of engineers and architects in Tripoli",
        newsDescription: "List of all the news and annoucements in the order of engineers and architects in Tripoli",
        technicalDocumentsDescription: "Technical Documents that may be useful for the engineers",
        trainingsDescription: "List of all the trainings offered by the order of engineers and architects in Tripoli",
        visitusDescription: "Visit the order of engineers and architects in Tripoli",
        engineerZoneDescription: "The engineers zone offer a variety of services and bouquets for all the engineers and architects",
        homepageDescription: "Home page of the official website for the order of engineers and architects in Tripoli",
        jobsDescription: "Jobs posted on the official website of the order of engineers and architects in Tripoli",
        reportsDescription: "Reports and statistics related to the order of engineers and architects in Tripoli",
        calculatorDescription: "The calculator containing registration fees and engineer fees help the engineers calculate the fees easily and quickly",
        technicalOfficePubs:"Technical Office Publications",
        download:"Download"
    },










    fr: {
        exploreOEA: "Explorez OEA",
        news: "Nouvelles",
        logout: "Sortir",
        login: "Entrer",
        homeTitle: "Ordre Des Ingénieurs & Architectes <br/>  à Tripoli, Liban",
        footerCopyrights: "Ordre Des Ingénieurs à Tripoli - Droits D'Auteur 2022",
        aboutOEA: "À Propos De l'Ordre",
        boardOfMembers: "Conseil D'Administration",
        organizationChart: "Organigramme",
        branches: "Branches",
        associations: "Comités",
        trainings: "Les Entrainements",
        events: "Evénements",
        gallery: "Galerie",
        lawsAndRegulations: "Lois et Règlements",
        engZone: "Zone Des Ingénieurs",
        contactUs: "Contactez Nous",
        contactOea: "Contactez OEA",
        contactLaboratory: "Contactez Le Laboratoire",
        contactTrainingCenter: "Contactez Centre d'entrainements",
        trainingCenter: "Centre d'entrainements",
        'training-Center': "Centre d'entrainements",
        visitUs: "Visitez Nous",
        visitOea: "Visitez OEA",
        visitLaboratory: "Visitez Le Laboratoire",
        visitTrainingCenter: "Visitez Centre d'entrainements",
        latestFeeds: "Dernières Nouvelles",
        aboutUs: "A Propos De Nous",
        upcomingEvents: "Evénements Prochains",
        privacyPolicy: "Politique De Confidentialité",
        termOfUse: "Termes Et Conditions",
        services: "Services",
        shares: "partager",
        likes: "Aime",
        views: "Vues",
        All: "Tout",
        all: "Tout",
        "Training-Center": "Centre D'entrainement",
        "OEA-Support": "Support OEA",
        readMore: "Lis Plus",
        dateStart: "Date Début",
        startingFrom: "Début De:",
        dateEnd: "Date Fin ",
        instructors: "Instructeurs",
        alreadyRegistered: "Vous êtes déjà inscrit",
        viewRegistrationDetails: "Voir les détails de l'enregistrement",
        register: "Registrez",
        allDay: "Tout Le Jours",
        start: "Début",
        ok: "D'accord",
        end: "Fin",
        Announcements: "Annonces",
        Activities: "Activités",
        Mortality: "Mortalités",
        discountsAndBenefits: "Remises et Avantages",
        addCompany: "Ajouter L'entreprise",
        companyName: "Nom de l'entreprise",
        companyLocation: "Lieu de l'entreprise",
        uploadCompanyLocation: "Lieu de l'entreprise",
        uploadCompanyLogo: "Logo de l'entreprise",
        "Companies Directory": "Répertoire des entreprises",
        companyPhone: "Numero de l'entreprise",
        companyEmail: "Email de l'entreprise",
        companyWebsite: "Website de l'entreprise",
        companyDescription: "Description de l'entreprise",
        "Add Company": "Ajouter une entreprise",
        insurance: "Assurance",
        registrationTo: "Inscription pour",
        technicalDocuments: "Documents Techniques",
        MyRegistrationFor: "Mes Inscriptions Pour",
        myRegistration: "Mon Inscription",
        name: "Nom",
        email: "Courier électronique",
        phoneNumber: "Numéro de téléphone",
        additionalInfo: "Information Additionelle",
        viewTrainingDetails: "Détails de l'entrainements",
        cancelRegistration: "Annuler l'enregistrement",
        editRegistration: "Changer l'enregistrement",
        engDirectory: "Annuaire des ingénieurs",
        skills: "Compétences",
        major: "Profession",
        myComplaints: "Mes suggestions",
        newComplaint: "Nouvelle suggestion",
        complaint: "Suggestion",
        complaintImage: "Image de la suggestion",
        complaintVoice: "Vocal de la suggestion",
        complaintDescription: "Description de la suggestion",
        complaintStatus: "Status de la suggestion",
        "MY SUGGESTIONS": "MES SUGGESTIONS",
        "ADD SUGGESTION": "AJOUTER UNE SUGGESTION",
        recordYourComplaint: "Enregistrez votre suggestion",
        uploadSuggestionImage: "Télécharger Votre Suggestion en Image",
        uploadingVoice: "Téléchargement de l'enregistrement vocal",
        complaintAdded: "Suggestion soumise",
        editComplaint: "Modifier la suggestion",
        edit: "Modifier",
        voiceFailedToLoad: "Échec du chargement, nouvelle tentative",
        complaintDeleted: "Suggestion supprimée",
        noComplaints: "Vous n'avez aucune suggestion",
        initiated: "Initié",
        share: "Partage",
        underInvestigation: "Sous enquête",
        closed: "Fermé",
        sureToDeleteComplaint: "Êtes-vous sûr de vouloir supprimer cette suggestion?",
        errorUploadingVoice: "Erreur lors du téléchargement de la voix",
        ciscoTrainings: "Entrainements Cisco",
        calculator: "Calculatrice",
        "Registration Fees": "Frais D'inscription",
        "Engineer Fees": "Frais D'ingenieur",
        back: "Retour",
        calculatorResultNote: "C'est un resultat provisoire. Vous devez contacter l'ordre pour avoir les tariffs précis",
        usdRate: "1 USD = 1500 LBP",
        engFees: "Frais D'ingenieur",
        penaltyRate: "Penalty rate",
        penaltyAmount: "Montant de la pénalité",
        numberOfLateYears: "Nombre d'années depuis l'obtention du diplôme",
        year: "Année(s)",
        registrationFees: "Frais d'affiliation",
        annualFees: "Frais annuels",
        priceOfTerritory: "Coût du projet",
        nbyears: "Nombre d'années",
        pickCalculationMethod: "Méthode de calcul",
        byArea: "Par surface",
        byCost: "Par coût",
        projectTotal: "Surface du projet",
        sqm: "M²",
        inputValue: "Entrer la valeur",
        projectCategory: "Catégorie du projet",
        projectSubCategory: "Sous-catégorie du projet",
        priceValueRange: "Gamme de prix",
        complexLevel: "Niveau complexe",
        calculate: "Calculer",
        constructionTable: "Table de construction",
        percentageOfEngineerFees: "Pourcentage des frais d'ingénieur",
        pricePer1sqm: "Prix par 1 m² (selon table)",
        percentageOfEngFees: "Pourcentage des honoraires d'ingénieur (selon le tableau)",
        primaryStudyFees: "Frais d'étude primaire APS",
        detailedStudyFees: "Frais d'étude détaillés APS",
        licenceFileFees: "Frais de dossier de licence",
        orderFees: "Frais de l'Ordre",
        orderFeesOutside: "Frais de l'Ordre - en dehors de Tripoli",
        designFees: "Frais de conception et de détails de direction",
        executiveDocumentFees: "Frais de document exécutif",
        supervisingFees: "Frais de supervision",
        constructionLicenseFees: "Valeur de la licence de travail",
        executivePlansFees: "Frais des plans exécutifs",
        result: "Résultat",
        select: "Sélectionner",
        simple: "Simple",
        normal: "Ordinaire",
        luxurious: "Luxueux",
        complicated: "Complexe",
        returnsToPartEng: "Retourné au co-ingénieur 40% des retours de OEA",
        electEngFees: "Payé à l'ingénieur électricien à partir des déclarations de OEA",
        mechEngFees: "Payé à l'ingénieur en mécanique à partir des déclarations de l'OEA",
        quotaPercentagePartFees: "Le pourcentage du total des frais du participant (selon le ratio de répartition des quotas)",
        elecPerc: "Pourcentage de la rémunération totale de l'ingénieur en électricité",
        mechanicalPerc: "Pourcentage de la rémunération totale de l'ingénieur en mécanique",
        remainingForMainEng: "Net restant pour l'ingénieur responsable des déclarations OEA",
        fundReceipts: "Valeur totale des recettes payables au Fonds",
        pensionFund: "Fonds De Pension",
        reports: "Rapports",
        payOnline: "Payer Electroniquement",
        polls: "Les sondages",
        jobs: "Emplois",
        position: "Position",
        deadline: "Date Limite",
        qualifications: "Conditions Necessaires",
        summary: "Résumé",
        jobDetails: "Détails",
        contactInfo: "Informations de contact",
        profile: "Mon Profil",
        facebook: "Facebook",
        instagram: "Instagram",
        linkedIn: "Linkedin",
        twitter: "Twitter",
        gender: "Genre",
        male: "Mâle",
        skills: "Compétences",
        searchSkill: "Rechercher dans les compétences",
        complaints: "Suggestions",
        engProfile: "Profil",
        cvUrl: "CV",
        female: "Femelle",
        uploadingPdf: "Téléchargement de PDF",
        errorUploadingPdf: "Erreur lors du téléchargement du fichier PDF",
        isPublicProfile: "Visible dans l'annuaire des ingénieurs",
        major: "Profession",
        myRegistrations: "Mes enregistrements",
        myComplaints: "Mes suggestions",
        save: "Sauvegarder",
        delete: "Effacer",
        submit: "Soumettre",
        next: "Suivant",
        register: "Registrez",
        firstName: "Prénom",
        middleName: "Prénom Du Père",
        lastName: "Nom",
        countryCode: "Code postal",
        facebookAccount: "Compte Facebook",
        twitterAccount: "Compte Twitter",
        emailAddress: "Addresse du Courier électronique",
        linkedinAccount: "Compte Linkedin",
        instagramAccount: "Compte Instagram",
        signin: "Connectez-vous",
        resetPassword: "Réinitialiser le mot de passe",
        username: "Nom d'utilisateur",
        password: "Mot de passe",
        rememberMe: "Se souvenir de moi",
        joinOEA: "Nouveau compte",
        forgotPassword: "Mot de passe oublié",
        enroll: "Enroll",
        engineerId: "ID Ingénieur",
        firstName: "Prénom",
        changePhoneNumber: "Changer votre numéro de téléphone",
        unableToConnectToServer: "Une erreur est survenue, veuillez réessayer ultérieurement",
        email: "Courier électronique",
        phoneNumber: "Numéro de téléphone",
        enterEngId: "Veuillez entrer votre identifiant d'ingénieur",
        resetCodeReceived: "Vous avez reçu un code de réinitialisation du mot de passe?",
        rememberedPassword: "Vous vous souvenez de votre mot de passe ?",
        pleaseChooseResetPasswordMethod: "Recevoir le code via: ",
        sms: "Message Telephonique",
        requestToJoin: "Demande d'adhésion",
        tryAgainError: "Une erreur est survenue, veuillez réessayer ultérieurement",
        useCodeToLogin: "Veuillez utiliser la vérification du code pour vous connecter",
        enterNewPass: "Entrez un nouveau mot de passe",
        confirmNewPass: "Confirmer le nouveau mot de passe",
        alreadySentRequest: "Déjà envoyé une demande d'inscription?",
        verifyEnrollement: "Vérifier l'inscription",
        alreadyHaveAccount: "Vous avez déjà un compte?",
        confirmEnroll: "Confirmer l'inscription",
        signinSentence: "Connectez-vous pour bénéficier des services d'ingénieurs en ligne",
        smsCode: "Code de vérification SMS",
        retypePass: "Retaper le mot de passe",
        facingProblem: "Face à un problème ?",
        sendNewRequest: "Envoyer une nouvelle demande d'inscription",
        send: "Envoyer",
        newUser: "Nouvel utilisateur?",
        joinOea: "Rejoignez l'OEA",
        forgotPass: "Mot de passe oublié?",
        fullName: "Nom complet",
        addPerson: "Ajouter une personne",
        removePerson: "Supprimer la personne",
        comingSoon: "À venir",
        pleaseWait: "S'il vous plaît, attendez...",
        "register-to-event": "S'inscrire à l'événement",
        "register-to-training": "S'inscrire à la formation",
        home: "Acceuil",
        eorder: "Ordre Electronique",
        introSentence: "Pour effectuer vos opérations administratives et financières, veuillez cliquer sur le lien suivant:",
        clickHere: "Cliquez ici",
        renewJobDeclaration: "Renouveler la déclaration d'emploi",
        continueFeesPayment: "Continuer le paiement des frais",
        statementYear: "Attestation Professionnelle Pour L'année",
        generalInfo: "Informations Generales",
        engineerName: "Nom De L'ingenieur",
        chapter: "",
        subChapter: "",
        workType: "Type de travail",
        workLocation: "Lieu de travail",
        homeAddress: "Adresse du domicile",
        country: "Pays",
        province: "Province",
        city: "Ville",
        district: "Quartier",
        street: "rue",
        building: "Immeuble",
        floor: "Étage",
        appartment: "Appartement",
        mobile: "Mobile",
        isForMailing: "(Est pour l'envoi)",
        workAddress: "Adresse de travail",
        oops: "Oups! Un problème est survenu lors du chargement de la page",
        tryAgain: "Cliquez ici pour essayer à nouveau",
        law: `En application de l'article 8-9 du règlement intérieur :
        L'ingénieur doit noter personnellement tous les renseignements contenus dans la déclaration professionnelle lui appartenant sous sa responsabilité.
        Et que toute fausse information contenue dans la déclaration susvisée expose son propriétaire à la suspension des transactions dans le Syndicat
        conformément à une décision rendue par le Conseil du Syndicat à cet égard.
        Elle le soumet également aux sanctions disciplinaires prévues par la loi.`,
        IAccept: "J'accepte",
        clickToContinue: "Clique ici pour continuer",
        quotaLaw: "Seuls les ingénieurs-conseils (qui obtiennent un quota) sont autorisés à renouveler l'attestation professionnelle en début d'année, soit le firstDate. Quant aux autres ingénieurs, leurs données professionnelles sont renouvelées en début d'année syndicale, soit le secondDate.",
        visitOEA: `Vous ne pouvez pas changer votre position en ligne. Pour tout changement de statut d'ingénieur-conseil, vous devez vous présenter personnellement au syndicat <br/><br/>Ou contacter le numéro suivant : 06413110 <br/>Ou via e-mail : info@oea-Tripoli.org.lb`,
        statusPreviousYear: "Le statut de votre attestation professionnelle pour l'année précédente est :",
        changePosition: "Changer de position",
        continueSamePosition: "Continuité dans la même position",
        delayVisitOea: `La date de votre dernière déclaration professionnelle remonte à plus d'un an, vous devez donc vous présenter personnellement au syndicat <br/><br/>Ou contacter le numéro suivant : 06413110 <br/>Ou par e-mail : info@oea-Tripoli.org.lb`,
        everythingUpToDate: "Vous avez déjà renouvelé votre déclaration d'emploi. Vous pouvez seulement voir votre ancienne déclaration d'emploi ",
        updatesText: `En cas de changement, veuillez modifier votre adresse personnelle et votre adresse professionnelle`,
        uploadDocuments: "Veuillez télécharger ces documents",
        noDocuments: "Aucun document requis",
        remarks: "Remarques",
        responsibility: "Les informations reçues sont sous votre responsabilité, et toute fausse information peut vous exposer à la suspension de vos transactions et à des sanctions disciplinaires.",
        finish: "Terminer la transaction",
        enterPassword: "Entrer Le Nouveau Mot De Passe",
        workForEngineer: "Travail Pour Un Ingénieur",
        workForEngineerID: "Travail Pour Un Ingénieur ID",
        partnerEngineer: "Ingénieur Partenaire",
        partnerEngineerId: "Ingénieur Partenaire ID",
        quotaForEngineer: "Ingénieur Beneficiaire Du Quota",
        quotaForEngineerID: "Ingénieur Beneficiaire Du Quota ID",
        govInstitute: "Gov Institute",
        govInstituteID: "Gov Institute ID",
        govInstituteDesc: "Description Gov Institute",
        downloadFile: "Pour telecharger le contrat de partenariat, cliquez ici",
        downloadFileReceipt: "Pour telecharger le reçu, cliquez ici",
        pictureNote: "Veuillez noter que vous ne pouvez télécharger qu'une image ou un fichier pdf d'une taille maximale de 1 Mo",
        bigFile: "Veuillez télécharger un fichier de moins de 1 annualSubscriptionMo",
        requestEnrollment: "Votre demande est en cours d'examen<br/><br/> Nous vérifierons vos informations et vous enverrons un code sur votre numéro et email pour pouvoir vous connecter",
        userDoesNotExists: "Numéro d'utilisateur n'existe pas",
        wrongPassword: "Mot de passe incorrecte",
        wrongData: "Données Incorrectes",
        alreadyExists: "Utilisateur existe déjà",
        doseNotExists: "Utilisateur n'existe pas",
        emailDoesNotExists: "Email invalide",
        pendingReq: "Votre demande est en attente d'approbation",
        expiredCode: "Le code de vérification a expiré",
        requestDoesNotExist: "La demande n'existe pas",
        codeUsed: "Le code de vérification déjà utilisé",
        wrongCode: "Code de vérification incorrect",
        confirmEnrollment: "Confirmer l'inscription ",
        yourCV: "Votre CV",
        updateTypeLocationWork: "Modifier le type et le lieu de travail",
        requestExists: "Vous avez déjà envoyé une demande",
        engZoneSentence: "La Zone des Ingénieurs offre à ses membres un bouquet personnalisé de services et de fonctionnalités pour améliorer leur expérience et répondre à leurs besoins.",
        loginSentence: `Connectez-vous et profitez de la zone des ingénieurs OEA`,
        'Building License Transactions': 'Transactions de licence de construction',
        'Restoration / Connection Permit / Demolition / Designation': 'Rétablissement / Connexion Permis / Démolition / Désignation',
        searchIn: 'Rechercher dans',
        registrationclosed: 'inscription fermée',
        noDataAvailable: 'Pas De Données Disponibles',
        wrongConfirmPassword: "Merci de vérifier que vos mots de passe correspondent",
        wrongOldPassword: "Votre ancien mot de passe semble être incorrect",
        changePassword: "Modifier le mot de passe",
        passwordChangedSuccessfully: "Votre mot de passe a été changé avec succès. Utilisez votre nouveau mot de passe pour vous connecter",
        personInfo: "Informations Personelles",
        status: "Statut",
        financialStatement: "États Financiers",
        mandatoryUnpaidDues: "Cotisations impayées obligatoires",
        optionalUnpaidDues: "Cotisations impayées",
        unpaidReceipt: "Reçu impayé",
        date: "Date",
        dueDate: "Date d'échéance",
        amount: "Montant",
        numberReceipt: "Numéro de reçu",
        description: "Description",
        payReceiptB: "Veuillez payer vos reçus impayés avant d'émettre un nouveau reçu",
        createdJobDeclaration: "Vos transactions administratives sont terminées",
        generatedReceipt: "Votre transaction financière a été complétée avec succès",
        printReceipt: "Retour à l'impression du reçu",
        noFinancialStatment: "Vous n'avez aucune transaction financière",
        print: "Imprimer",
        cantPrint: "Cette transaction est ancienne, si elle a été payée, merci d'en envoyer une copie à l'email suivant",
        skip: "Sauter",
        batch: "lot",
        paymentTerms: "Modalités de paiement",
        years: "Les années",
        withoutInterest: "sans intérêt",
        and: "et",
        feeAmount: "Montant des frais",
        interestAmount: "Montant des intérêts",
        pendingOperation: "Vous avez une précédente transaction d'abonnement annuel, SVP contacte le dpt financier",
        annualSubscription: "Souscription annuelle",
        fileCode: "Code de fichier",
        engineerNumber: "Numéro d'ingénieur",
        fileType: "Type de fichier",
        description: "Descriptif",
        notes: "Remarques",
        dateOfReceipt: "Date de réception",
        deliveryDate: "Date de livraison",
        currentStatusFile: "L'état actuel du dossier",
        lastUserHasFile: "Le nom du dernier utilisateur ou employé qui a le dossier",
        licenseFileSentence: "Pour effectuer vos transactions techniques , veuillez cliquer sur le lien suivant:",
        licenseFile: "Transactions techniques",
        enterFileCode: "Entrez votre code d'opération",
        extension: "Extension",
        employeeName: "Nom de l'Employé",

        //SEO Description
        aboutOeaDescription: "A propos de l'ordre des ingénieurs et architectes à Tripoli",
        boardmembersDescription: "Conseil des membres de l'ordre des ingénieurs et architectes de Tripoli",
        branchesDescription: "Branchyes de l'ordre des ingénieurs et architectes de Tripoli",
        committeesDescription: "Les commissions de l'ordre des ingénieurs et architectes de Tripoli",
        organizationDescription: "L'organigramme de l'ordre des ingénieurs et architectes de Tripoli",
        benefitsDescription: "Réductions et avantages offerts par l'Ordre des Ingénieurs et Architectes de Tripoli",
        companiesDescription: "Annuaire des entreprises où vous pouvez trouver de nombreuses entreprises avec leurs coordonnées",
        contactusDescription: "Contactez l'ordre des ingénieurs et des architectes de Tripoli",
        insuranceDescription: "Service d'assurance offert par l'ordre des ingénieurs et architectes à Tripoli",
        engineersDirectoryDescription: "Annuaire des ingénieurs où vous pouvez trouver un grand nombre de profils d'ingénieurs avec leurs coordonnées",
        jobDeclarationDescription: "Renouveler la déclaration d'emploi des ingénieurs en ligne et de manière rapide",
        eventsDescription: "Liste de tous les événements réalisés par l'ordre des ingénieurs et architectes à Tripoli",
        galleryDescription: "Galerie de photos des événements et des activités en collaboration avec l'ordre des ingénieurs et des architectes à Tripoli",
        lawsDescription: "Lois et règlements de l'ordre des ingénieurs et des architectes à Tripoli",
        complaintsDescription: "Suggestions postées par les ingénieurs",
        myregistrationsDescription: "Mes inscriptions aux manifestations et formations de l'ordre des ingénieurs et architectes à Tripoli",
        newsDescription: "Liste de toutes les actualités et annonces de l'ordre des ingénieurs et architectes à Tripoli",
        technicalDocumentsDescription: "Documents techniques pouvant être utiles aux ingénieurs",
        trainingsDescription: "Liste de toutes les formations proposées par l'ordre des ingénieurs et architectes à Tripoli",
        visitusDescription: "Visitez l'ordre des ingénieurs et des architectes à Tripoli",
        engineerZoneDescription: "La zone ingénieurs offre une variété de services et de bouquets pour tous les ingénieurs et architectes",
        homepageDescription: "Page d'accueil du site officiel de l'ordre des ingénieurs et architectes de Tripoli",
        jobsDescription: "Emplois affichés sur le site officiel de l'ordre des ingénieurs et architectes à Tripoli",
        reportsDescription: "Rapports et statistiques relatifs à l'ordre des ingénieurs et architectes à Tripoli",
        calculatorDescription: "Le calculateur contenant les frais d'inscription et les frais d'ingénieur aide les ingénieurs à calculer les frais facilement et rapidement",
        technicalOfficePubs:"Publications du bureau technique",
        download:"Telecharger"
    },










    ar: {
        exploreOEA: "اكتشف النقابة",
        news: "الاخبار",
        logout: "خروج",
        login: "دخول",
        homeTitle: "نقابة المهندسين في طرابلس لبنان ",
        footerCopyrights: "نقابة المهندسين في طرابلس حقوق النشر ٢٠٢٢",
        aboutOEA: "حول النقابة",
        boardOfMembers: "أعضاء المجلس",
        organizationChart: "الهيكل التنظيمي",
        branches: "الفروع",
        associations: "اللجان والروابط",
        trainings: "الدورات التدريبية",
        events: "الأحداث",
        gallery: "المعرض",
        lawsAndRegulations: "الانظمة والقوانين",
        ok: "تم",
        engZone: "زاوية المهندس",
        eorder: "النقابة الالكترونية",
        contactUs: "تواصل معنا",
        contactOea: "النقابة",
        contactLaboratory: "المختبر",
        contactTrainingCenter: "مركز التدريب",
        trainingCenter: "مركز التدريب",
        'training-Center': "مركز التدريب",
        visitUs: "مركزنا",
        visitOea: "مركز النقابة",
        visitLaboratory: "مركز المختبر",
        visitTrainingCenter: "مركز التدريب",
        latestFeeds: "جديدنا",
        aboutUs: "حول النقابة",
        upcomingEvents: "النشاطات القادمة",
        privacyPolicy: "سياسة الخصوصية",
        termOfUse: "أسئلة شائعة",
        services: "خدمات",
        shares: "مشاركة",
        likes: "إعجاب",
        views: "رؤية",
        All: "الكل",
        all: "الكل",
        "Training-Center": "مركز التدريب",
        "OEA-Support": "مشاركة النقابة",
        readMore: "اقرأ المزيد",
        dateStart: "تاريخ البدء",
        startingFrom: "إبتداءاً من:",
        dateEnd: "تاريخ الانتهاء",
        instructors: "المدربون",
        alreadyRegistered: "أنت مسجل مسبقا",
        viewRegistrationDetails: "تفاصيل التسجيل",
        register: "التسجيل",
        allDay: "كل اليوم",
        start: "البدء",
        end: "الانتهاء",
        Announcements: "التعاميم",
        Activities: "أنشطة",
        Mortality: "وفيات",
        discountsAndBenefits: "خصومات ومزايا",
        addCompany: "اضافة الشركة",
        companyName: "اسم الشركة",
        companyLocation: "موقع الشركة",
        uploadCompanyLocation: "موقع الشركة",
        uploadCompanyLogo: "شعار الشركة",
        "Companies Directory": "دليل الشركات",
        result: "النتيجة",
        companyPhone: "هاتف الشركة",
        companyEmail: "بريد الشركة",
        companyWebsite: "موقع الشركة",
        companyDescription: "وصف الشركة",
        "Add Company": "اضافة شركة",
        insurance: "التأمين",
        registrationTo: "التسجيل في ",
        technicalDocuments: "وثائق تقنية",
        MyRegistrationFor: "تسجيلاتي في",
        myRegistration: "تسجيلي",
        name: "الاسم",
        date: "التاريخ",
        email: "البريد الالكتروني",
        phoneNumber: "رقم الهاتف",
        additionalInfo: "معلومات اضافية",
        viewTrainingDetails: "تفاصيل الدورة",
        cancelRegistration: "إلغاء التسجيل",
        editRegistration: "تعديل التسجيل",
        engDirectory: "دليل المهندسين",
        skills: "المهارات",
        major: "المهنة",
        myComplaints: "اقتراحاتي",
        newComplaint: "اقتراح جديد",
        complaint: "إقتراح",
        complaintImage: "الصورة",
        complaintVoice: "الصوت",
        complaintDescription: "التفاصيل",
        complaintStatus: "الحالة",
        "MY SUGGESTIONS": "اقتراحاتي",
        "ADD SUGGESTION": "اضافة اقتراح",
        recordYourComplaint: "سجل اقتراحك",
        uploadSuggestionImage: "حمّل صورة الاقتراح",
        uploadingVoice: "جاري تحميل الصوت",
        complaintAdded: "تم قبول الاقتراح",
        editComplaint: "تعديل الاقتراح",
        edit: "تعديل",
        voiceFailedToLoad: "فشل التحميل ، أعد المحاولة",
        complaintDeleted: "تم إلغاء الاقتراح",
        noComplaints: "ليس لديك اقتراحات وشكاوى",
        initiated: "تم الاستلام",
        share: "مشاركة",
        underInvestigation: "قيد التحقيق",
        closed: "مغلق",
        sureToDeleteComplaint: "هل أنت متأكد من أنك تريد حذف هذا الاقتراح؟",
        errorUploadingVoice: "خطأ في تحميل الصوت",
        ciscoTrainings: "دورات Cisco",
        calculator: "حاسبة المهندس",
        "Registration Fees": "رسوم الانتساب ",
        "Engineer Fees": "بدل اتعاب المهندس",
        back: "عودة",
        calculatorResultNote: "هذه النتائج تقديرية وقد تختلف حسب حالة المهندس وبلد إقامته ولا بد من مراجعة النقابة للحصول على رسوم الانتساب الفعلية.",
        usdRate: "دولار 1 = 1500 ليرة لبنانية",
        engFees: "بدل اتعاب المهندس",
        penaltyAmount: "قيمة الغرامة",
        numberOfLateYears: "عدد السنوات منذ التخرج",
        year: "سنة",
        registrationFees: "رسم الانتساب",
        annualFees: "الرسم السنوي",
        nbyears: "عدد السنوات",
        pickCalculationMethod: "طريقة الحساب",
        byArea: "حسب المساحة",
        byCost: "حسب الكلفة",
        projectTotal: "مساحة المشروع",
        sqm: "متر مربع",
        inputValue: "القيمة",
        projectCategory: "نوع المبنى",
        projectSubCategory: "وجهة استعمال المبنى",
        priceValueRange: "قيمة المشروع التقديرية",
        complexLevel: "درجة تعقيد المبنى",
        calculate: "احسب",
        constructionTable: "جدول البناء",
        pricePer1sqm: "كلفة المتر المربع الواحد حسب الجدول",
        percentageOfEngineerFees: "النسبة المؤية لأتعاب المهندس من كلفة المشروع",
        percentageOfEngFees: "النسبة المؤية لأتعاب المهندس من كلفة المشروع حسب الجدول",
        priceOfTerritory: "كلفة المشروع",
        primaryStudyFees: "قيمة الدراسة التمهيدية الأولية APS",
        detailedStudyFees: "قيمة الدراسة التمهيدية التفصيلية APD",
        licenceFileFees: "قيمة ملف الرخصة (تدفع في النقابة وترتجع كلها للمهندس بعد شهر)",
        orderFees: "رسوم النقابة النسبية",
        orderFeesOutside: "رسوم النقابة النسبية - خاص خارج الشمال",
        designFees: "قيمة التصاميم والتفاصيل التنفيذية الاجمالية",
        executiveDocumentFees: "قيمة مستندات التنفيذ",
        supervisingFees: "قيمة الاشراف على التنفيذ",
        constructionLicenseFees: "قيمة رخصة الأشغال",
        executivePlansFees: "قيمة الخرائط التنفيذية",
        returnsToPartEng: "يعود للمهندس المشارك 40% من مرتجعات النقابة",
        electEngFees: "يدفع لمهندس الكهرباء من مرتجعات النقابة",
        mechEngFees: "يدفع لمهندس الميكانيك من مرتجعات النقابة",
        quotaPercentagePartFees: "النسبة من بدل الأتعاب الإجمالي للمشارك (حسب نسبة توزيع الكوتا)",
        elecPerc: "النسبة من بدل الأتعاب الإجمالي لمهندس الكهرباء",
        mechanicalPerc: "النسبة من بدل الأتعاب الإجمالي لمهندس الميكانيك",
        remainingForMainEng: "صافي المتبقي للمهندس المسؤول من مرتجعات النقابة",
        fundReceipts: "مجموع قيمة الإيصالات المتوجبة للتسديد على الصندوق",
        pensionFund: "صندوق التقاعد",
        reports: "التقارير",
        payOnline: "الدفع الاكتروني",
        polls: "استطلاعات الرأي",
        jobs: "الوظائف",
        position: "الوظيفة",
        deadline: "الموعد الأخير",
        qualifications: "الشروط المطلوبة",
        summary: "ملخص",
        jobDetails: "تفاصيل",
        contactInfo: "معلومات الاتصال",
        profile: "حسابي الشخصي",
        facebook: "فايسبوك",
        instagram: "إينستاجرام",
        linkedIn: "لنكدان",
        twitter: "تويتر",
        gender: "الجنس",
        male: "ذكر",
        skills: "المهارات",
        major: "المهنة",
        searchSkill: "البحث في المهارات",
        complaints: "الاقتراحات",
        engProfile: "الملف الشخصي",
        cvUrl: "السيرة الذاتية",
        female: "أنثى",
        uploadingPdf: "جاري تحميل الملف",
        errorUploadingPdf: "خطأ في تحميل الملف",
        isPublicProfile: "مرئي في دليل المهندسين",
        major: "المهنة",
        myRegistrations: "تسجيلاتي",
        myComplaints: "اقتراحاتي",
        save: "حفظ",
        delete: "حذف",
        submit: "حفظ",
        next: "التالي",
        engineerId: "رقم تعريف المهندس",
        firstName: "الاسم",
        lastName: "الشهرة",
        middleName: "اسم الأب",
        countryCode: "رمز البلد",
        facebookAccount: "حساب Facebook",
        twitterAccount: "حساب Twitter",
        emailAddress: "عنوان البريد الالكتروني",
        linkedinAccount: "حساب Linkedin",
        instagramAccount: "حساب Instagram",
        signin: "تسجيل الدخول",
        resetPassword: "إعادة تعيين كلمة المرور",
        username: "اسم المستخدم",
        password: "كلمة المرور",
        rememberMe: "تذكرني",
        joinOEA: "حساب جديد",
        forgotPassword: "نسيت كلمة المرور",
        enroll: "التسجيل",
        changePhoneNumber: "عدّل رقم هاتفك",
        unableToConnectToServer: "حدث خطأ ، يرجى المحاولة مرة أخرى في وقت لاحق",
        firstName: "الاسم",
        lastName: "الشهرة",
        email: "البريد الإلكتروني",
        phoneNumber: "رقم الهاتف",
        allFieldMandatory: "كل الحقول إلزامية",
        enrollThankYou: "شكراً لاهتمامك بالتسجيل مع OEA طرابلس",
        enrollDetailsSubmitted: "تم إرسال التفاصيل الخاصة بك بنجاح",
        enrollInfo: " نحن الآن نراجع معلوماتك للتحقق من الأهلية. ستحصل على رسالة قصيرة على رقم هاتفك فيها رمز خلال الـ 24 ساعة القادمة.",
        enrollCode: "أكتب الرمز الذي تلقيته",
        didntReceiveEnrollCode: "لم تستلم الكود؟",
        resendCode: "إعادة إرسال الرمز",
        correctPhone: "تصحيح رقم الهاتف",
        welcomeInfo: "مرحبا بكم في التطبيق المحمول OEA طرابلس",
        createPasswordInfo: "قم بإنشاء كلمة المرور الخاصة بك",
        confirmPassword: "تأكيد كلمة المرور",
        goToSignIn: "انتقل إلى تسجيل الدخول",
        iHaveACode: "لدي رمز لتفعيل الحساب",
        activateAccount: "تفعيل حساب",
        enterEngId: "ادخل رقم التعريف النقابي",
        resetCodeReceived: "هل تلقيت رمز إعادة تعيين كلمة المرور؟",
        rememberedPassword: "تذكرت كلمة المرور الخاصة بك؟",
        sms: "رسالة على الهاتف",
        pleaseChooseResetPasswordMethod: "هل ترغب في الحصول على الرمز لإعادة تعيين كلمة المرور الخاصة بك عبر:",
        requestToJoin: "إرسال طلب إنضمام",
        tryAgainError: "الرجاء اعادة المحاولة لاحقا",
        useCodeToLogin: "الرجاء استخدام من رمز التحقق لتسجيل الدخول",
        enterNewPass: "أدخل كلمة مرور جديدة",
        confirmNewPass: "تأكيد كلمة المرور الجديدة",
        alreadySentRequest: "لقد أرسلت طلب تسجيل الدخول؟",
        verifyEnrollement: "تحقق من التسجيل",
        alreadyHaveAccount: "هل لديك حساب؟",
        confirmEnroll: "تأكيد التسجيل",
        signinSentence: "قم بتسجيل الدخول للاستفادة من خدمات المهندسين عبر الإنترنت",
        smsCode: "رمز التحقق عبر الرسائل القصيرة",
        retypePass: "أعد إدخال كلمة السر",
        facingProblem: "هل تواجه مشكلة؟",
        sendNewRequest: "أرسل طلب تسجيل جديد",
        send: "أرسل",
        newUser: "مستخدم جديد؟",
        joinOea: "انضم إلى OEA",
        forgotPass: "هل نسيت كلمة السر؟",
        fullName: "الاسم الكامل",
        addPerson: "إضافة شخص",
        removePerson: "إزالة الشخص",
        comingSoon: "قريبا",
        pleaseWait: "الرجاء الانتظار...",
        "register-to-event": "التسجيل في النشاط",
        "register-to-training": "التسجيل في التدريب",
        home: "الصفحة الرئيسية",
        enterPassword: "الرجاء ادخال كلمة سر",
        eorder: "النقابة الالكترونية",
        introSentence: "للقيام بمعاملاتكم الادارية والمالية الرجاء الضغط على الرابط التالي : ",
        clickHere: "اضغط هنا",
        renewJobDeclaration: "تجديد البيان المهني للسنة الحالية",
        continueFeesPayment: "استكمال دفع الرسوم للمعاملات السابقة",
        statementYear: "البيان المهني للسنة",
        generalInfo: "معلومات عامة",
        engineerName: "اسم المهندس",
        chapter: "الفصل",
        subChapter: "الفصل الفرعي",
        workType: "نوع العمل",
        workLocation: "مكان العمل",
        homeAddress: "عنوان المنزل",
        country: "دولة",
        province: "محافظة",
        district: "قضاء",
        city: "المدينة",
        street: "شارع",
        building: "ﺍﻟﺒﻨﺎﻳﺔ",
        floor: "الطابق",
        appartment: "الشقة",
        mobile: "الخليوي",
        isForMailing: "(للبريد)",
        workAddress: "عنوان العمل",
        oops: "حدثت مشكلة أثناء تحميل الصفحة",
        law: `عملا بالمادة ٨ - ٩ من النظام الداخلي :
        على المهندس ان يدون شخصيا كافة المعلومات الواردة في البيان المهني العائد له على مسؤوليته.
         وان أية معلومات خاطئة واردة في البيان المذكور تعرض صاحبها الى توقيف معاملات في النقابة 
         وفقا لقرار يصدر من مجلس النقابة بهذا للخصوص. كما تعرضه الى العقوبات التأديبية المنصوص عليها قانونيا. `,
        quotaLaw: "إن المهندسين الاستشاريين ( الذين يستحصلون على كوتا) هم فقط المخولين لتجديد البيان المهني في بداية السنة أي بتاريخ firstDate . أما المهندسين الاخرين فيتم تجديد بياناتهم المهنية في بداية السنة النقابية أي بتاريخ secondDate",
        visitOEA: `ان أي تغيير بوضعية المهندسين الاستشاريين يتوجب عليكم الحضور شخصيا الى النقابة أو التواصل معنا <br/><br/>على الرقم التالي: 06413110 <br/>أو عبر البريد الالكتروني: info@oea-Tripoli.org.lb`,
        statusPreviousYear: "وضعية بيانكم المهني للسنة السابقة هي : ",
        changePosition: "تعديل بالوضعية",
        continueSamePosition: "استمرارية في الوضعية ذاتها",
        delayVisitOea: `ان تاريخ آخر بيان مهني لحضرتكم يعود لأكثر من سنة ولذلك يتوجب عليكم الحضور شخصيا الى النقابة أو التواصل معنا<br/><br/> على الرقم التالي: 06413110 <br/>أو عبر البريد الالكتروني: info@oea-Tripoli.org.lb`,
        everythingUpToDate: ` ان بيانكم المهني لهذه السنة قد تم تقديمه سابقا. شكرا لكم. يمكنكم فقط الاطلاع على بيانكم المهني السابق.`,
        updatesText: "إذا كان هناك أي تغيير ، يرجى تعديل عنوان منزلك وعنوان عملك",
        tryAgain: "اضغط لاعادة المحاولة",
        IAccept: "أنا أوافق",
        clickToContinue: "اضغط هنا للاكمال",
        uploadDocuments: "الرجاء تحميل هذه المستندات",
        noDocuments: "لا توجد مستندات مطلوبة",
        remarks: "ملاحظات",
        responsibility: "ان المعلومات الواردة هي على مسؤوليتكم وأية معلومات خاطئة تعرضكم الى توقيف معاملاتكم والى عقوبات تأديبية",
        finish: "إنهاء المعاملة",
        workForEngineer: "يعمل لدى",
        workForEngineerID: "رقم تعريف الشريك",
        partnerEngineer: "الشركة/المهندس",
        partnerEngineerId: "رقم التعريف للشركة/المهندس",
        quotaForEngineer: "حق الكوتا ل",
        quotaForEngineerID: "رقم تعريف المهندس المستفيد من الكوتا",
        govInstitute: "الجهة الحكومية",
        govInstituteID: "رقم تعريف الجهة الحكومية",
        govInstituteDesc: "وصف الجهة الحكومية",
        downloadFile: "لتنزيل عقد الشراكة الرجاء الضغط هنا",
        downloadFileReceipt: "لتنزيل الايصال الرجاء الضغط هنا",
        pictureNote: "يرجى ملاحظة أنه يمكنك فقط تحميل صورة أو ملف بحجم أقصاه ١ ميغابايت",
        bigFile: "الرجاء تحميل ملف اقل من ١ ميغابايت",
        requestEnrollment: "طلبك قيد المراجعة <br/><br/>سوف نتحقق من معلوماتك ونرسل لك رمز على رقمك وبريدك الإلكتروني لتتمكن من تسجيل الدخول ",
        userDoesNotExists: "رقم المستخدم غير موجود",
        wrongPassword: "كلمة مرور خاطئة",
        wrongData: "بيانات خاطئة",
        alreadyExists: "هذا المستخدم موجود مسبقاً",
        doseNotExists: "هذا المستخدم غير موجود",
        emailDoesNotExists: "البريد الإلكتروني غير صحيح",
        pendingReq: "طلبك في انتظار الموافقة",
        expiredCode: "انتهت صلاحية رمز التحقق",
        requestDoesNotExist: "الطلب غير موجود",
        codeUsed: "رمز التحقق مستخدم بالفعل",
        wrongCode: "رمز التحقق خاطئ",
        confirmEnrollment: "تأكيد التسجيل",
        yourCV: "سيرتك الذاتية",
        updateTypeLocationWork: "تعديل نوع وموقع العمل",
        requestExists: "لقد أرسلت طلبًا مسبقا",
        engZoneSentence: "تقدم زاوية المهندسين باقة مخصصة من الخدمات والميزات لأعضاء النقابة لتعزيز خبرتهم ودعم احتياجاتهم",
        loginSentence: `سجلوا الدخول و استفيدوا من زاوية المهندسين`,
        'Building License Transactions': 'معاملات ترخيص البناء',
        'Restoration / Connection Permit / Demolition / Designation': 'معاملات ترميم / وصل بالتصريح / هدم / تصوينة',
        searchIn: 'البحث في',
        registrationclosed: 'التسجيل مغلق',
        noDataAvailable: 'لا توجد بيانات متاحة',
        wrongConfirmPassword: "يرجى التأكد من تطابق كلمات السر الخاصة بك",
        wrongOldPassword: "يبدو أن كلمة مرورك القديمة غير صحيحة",
        changePassword: "تغيير كلمة المرور",
        passwordChangedSuccessfully: "تم تغيير كلمة المرور الخاصة بك بنجاح. استخدم كلمة المرور الجديدة لتسجيل الدخول",
        personInfo: "معلومات شخصية",
        status: "الحالة",
        financialStatement: "المعاملة المالية",
        mandatoryUnpaidDues: "المستحقات غير المسددة الإلزامية",
        optionalUnpaidDues: "المستحقات غير المسددة",
        unpaidReceipt: "إيصال غير مدفوع",
        date: "التاريخ",
        dueDate: "تاريخ الاستحقاق",
        amount: "المبلغ",
        numberReceipt: "ايصال رقم",
        description: "الوصف",
        payReceiptB: "يرجى تسديد ايصالاتك الغير مدفوعة قبل اصدار ايصال جديد",
        createdJobDeclaration: "لقد تم انجاز معاملاتكم الادارية",
        generatedReceipt: "لقد تم انجاز معاملتكم المالية بنجاح",
        printReceipt: "العودة لطباعة الايصال",
        noFinancialStatment: "لا يوجد لديكم اي معاملات مالية",
        print: "طباعة",
        cantPrint: "هذه المعاملة قديمة، في حال كانت مدفوعة يرجى ارسال نسخة عبرالبريد الالكتروني التالي",
        skip: "تخطي",
        batch: "دفعة",
        paymentTerms: "شروط الدفع",
        years: "السنوات",
        withoutInterest: "من دون فوائد",
        and: "و",
        feeAmount: "مبلغ الرسوم",
        interestAmount: "مبلغ الفائدة",
        pendingOperation: "لديك معاملة اشتراك سنوي سابق معلق، يجب مراجعة النقابة",
        annualSubscription: "اشتراك سنوي",
        fileCode: "رقم الملف",
        engineerNumber: "رقم المهندس",
        fileType: "نوع الملف",
        description: "الوصف",
        notes: "الملاحظات",
        dateOfReceipt: "تاريخ الاستلام",
        deliveryDate: "تاريخ التسليم",
        currentStatusFile: "الوضع الحالي للملف",
        lastUserHasFile: "اسم اّخر مستخدم أو الموظف بحوذته الملف",
        licenseFileSentence: "للقيام بمعاملاتكم الفنية الرجاء الضغط على الرابط التالي : ",
        licenseFile: "المعاملات الفنية",
        enterFileCode: "ادخل رمز معاملتك الفنية",
        extension: "الرقم الداخلي",
        employeeName: "اسم الموظف",

        //SEO Description
        aboutOeaDescription: "نبذة عن نقابة المهندسين والمعماريين في طرابلس",
        boardmembersDescription: "مجلس أعضاء نقابة المهندسين والمعماريين في طرابلس",
        branchesDescription: "الفروع بترتيب المهندسين والمعماريين في طرابلس",
        committeesDescription: "لجان نقابة المهندسين والمعماريين في طرابلس",
        organizationDescription: "الهيكل التنظيمي لنقابة المهندسين والمعماريين في طرابلس",
        benefitsDescription: "الخصومات والمزايا التي تقدمها نقابة المهندسين والمعماريين في طرابلس",
        companiesDescription: "دليل الشركات حيث تجد العديد من الشركات بتفاصيلها",
        contactusDescription: "تواصل مع نقابة المهندسين والمعماريين في طرابلس",
        insuranceDescription: "خدمة التأمين التي تقدمها نقابة المهندسين والمعماريين في طرابلس",
        engineersDirectoryDescription: "دليل المهندسين حيث يمكنك العثور على عدد كبير من ملفات تعريف المهندسين مع معلومات الاتصال الخاصة بهم",
        jobDeclarationDescription: "تجديد تصريح عمل المهندسين عبر الإنترنت وبطريقة سريعة",
        eventsDescription: "قائمة بجميع الأحداث التي قام بها نقابة المهندسين والمعماريين في طرابلس",
        galleryDescription: "معرض لصور الفعاليات والأنشطة بالتعاون مع نقابة المهندسين والمعماريين في طرابلس",
        lawsDescription: "قوانين وأنظمة نقابة المهندسين والمعماريين في طرابلس",
        complaintsDescription: "اقتراحات من قبل المهندسين",
        myregistrationsDescription: "تسجيلاتي للفعاليات والدورات التدريبية بترتيب المهندسين والمعماريين في طرابلس",
        newsDescription: "قائمة بجميع الأخبار والإعلانات بترتيب المهندسين والمعماريين في طرابلس",
        technicalDocumentsDescription: "المستندات الفنية التي قد تكون مفيدة للمهندسين",
        trainingsDescription: "قائمة بجميع التدريبات التي تقدمها نقابة المهندسين والمعماريين في طرابلس",
        visitusDescription: "زيارة نقابة المهندسين والمعماريين في طرابلس",
        engineerZoneDescription: "منطقة المهندسين تقدم خدمات وباقات متنوعة لجميع المهندسين والمعماريين",
        homepageDescription: "الصفحة الرئيسية للموقع الرسمي لنقابة المهندسين والمعماريين في طرابلس",
        jobsDescription: "الوظائف المنشورة على الموقع الرسمي لنقابة المهندسين والمعماريين في طرابلس",
        reportsDescription: "تقارير وإحصاءات تتعلق بترتيب المهندسين والمعماريين في طرابلس",
        calculatorDescription: "الآلة الحاسبة المتضمنة لرسوم التسجيل وأتعاب المهندس تساعد المهندسين في احتساب الرسوم بسهولة وسرعة",
        technicalOfficePubs:"مطبوعات المكتب الفني",
        download:"تحميل"
    }
}

export default strings