import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Box, MenuItem, Select } from "@material-ui/core";
import { ClearAll, Language, Menu, PersonOutline } from "@material-ui/icons";
import Link from "components/Link";
import { setCookie } from "functions";
import Image from "next/image";
import { useRouter } from "next/router";
import { useContext, useEffect, useState } from "react";
import useStyles from "styles/Header.Style";
import useTranslation from "translations/hooks/useTranslation";
import SessionContext from "./session/SessionContext";

export default function Header() {
  const classes = useStyles();
  const router = useRouter();
  const { t } = useTranslation();

  let {
    actions: { signout, setSession },
    session: { user, lang },
  } = useContext(SessionContext);

  const [open, setOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [language, setLanguage] = useState(lang);

  useEffect(() => {
    window.innerWidth > 767 ? setIsOpen(true) : setIsOpen(false);

    window.addEventListener("resize", (e) => {
      window.innerWidth > 767 ? setIsOpen(true) : setIsOpen(false);
    });
  }, []);

  function handleLanguage(value) {
    setCookie("lang", value, 30);
    setLanguage(value);
    setSession({ lang: value });

    let {
      asPath,
      query: { lang = "en" },
    } = router;
    let path = asPath.replace(lang, value);
    asPath == "/" ? router.push(`/${value}`) : router.push(path);
  }

  function handleClose() {
    if (window.innerWidth > 767) return;
    setIsOpen(false);
  }

  function ProfilePicture() {
    return (
      <>
        <Link href="/userProfile" as="/userProfile">
          <Avatar className={classes.avatar}>
            {user.profile && user.profile.photoUrl ? (
              <Image
                src={`${user.profile.photoUrl}`}
                alt="My Profile"
                width={500}
                height={300}
              />
            ) : (
              <PersonOutline style={{ fontSize: 23, color: "#344450" }} />
            )}
          </Avatar>
        </Link>
        <Box onClick={signout}>
          <Box className={classes.span}>
            <FontAwesomeIcon icon={faSignOutAlt} />{" "}
            <span style={{ padding: 10 }}>{t("logout")}</span>
          </Box>
        </Box>
      </>
    );
  }

  return (
    <Box>
      <Box
        className={
          lang == "ar" ? classes.headerTopLeft : classes.headerTopRight
        }
      >
        <Box className={classes.span}>
          <Language />
          <Select
            value={language || "en"}
            label="Language"
            onChange={(e) => handleLanguage(e.target.value)}
            className={classes.select}
            disableUnderline
          >
            <MenuItem
              value="en"
              style={{ color: "#344450", fontWeight: "bold" }}
            >
              English
            </MenuItem>
            <MenuItem
              value="fr"
              style={{ color: "#344450", fontWeight: "bold" }}
            >
              Français
            </MenuItem>
            <MenuItem
              value="ar"
              style={{ color: "#344450", fontWeight: "bold" }}
            >
              العربية
            </MenuItem>
          </Select>
        </Box>
        {user && user.authToken && user._id ? (
          ProfilePicture()
        ) : (
          <Link href="/signin" as="/signin">
            <Box className={classes.span}>
              <PersonOutline style={{ fontSize: 28 }} />
              <span style={{ padding: 10 }}>{t("login")}</span>
            </Box>
          </Link>
        )}
      </Box>

      <Box className={classes.navbar}>
        <Box className={classes.subHeader}>
          <Box className={classes.span}>
            <Language fontSize="inherit" onClick={() => setOpen(true)} />
            <Select
              value={language || "en"}
              label="Language"
              onChange={(e) => handleLanguage(e.target.value)}
              className={classes.select}
              disableUnderline
              open={open}
              onClose={() => setOpen(false)}
              onOpen={() => setOpen(true)}
            >
              <MenuItem
                value="en"
                style={{ fontSize: 12, minHeight: 1, color: "#344450" }}
              >
                English
              </MenuItem>
              <MenuItem
                value="fr"
                style={{ fontSize: 12, minHeight: 1, color: "#344450" }}
              >
                Français
              </MenuItem>
              <MenuItem
                value="ar"
                style={{ fontSize: 12, minHeight: 1, color: "#344450" }}
              >
                العربية
              </MenuItem>
            </Select>
          </Box>

          {user && user.authToken && user._id ? (
            <Link href="/userProfile" as="/userProfile">
              <Avatar className={classes.avatar}>
                {user.profile && user.profile.photoUrl ? (
                  <Image
                    src={`${user.profile.photoUrl}`}
                    alt="profile"
                    width={"90%"}
                    height={"100%"}
                  />
                ) : (
                  <PersonOutline style={{ fontSize: 23, color: "#344450" }} />
                )}
              </Avatar>
            </Link>
          ) : (
            <Link href="/signin" as="/signin">
              <Box className={classes.span2}>
                <PersonOutline />
              </Box>
            </Link>
          )}
        </Box>

        <Link href="/" as="/">
          <Box className={classes.flexRow}>
            <Box
              className={lang == "ar" ? classes.logoRight : classes.logoLeft}
            >
              <Image
                src="/logo.png"
                alt="OEA Logo"
                width={"100%"}
                height={"100%"}
              />
            </Box>
            <Box
              className={classes.logoText}
              dangerouslySetInnerHTML={{ __html: t("homeTitle") }}
            />
          </Box>
        </Link>

        {!isOpen ? null : (
          <Box
            className={
              lang == "ar" ? classes.navmenuRight : classes.navmenuLeft
            }
          >
            <Box className={classes.divNavLink}>
              <a className={classes.navlinkList}>{t("exploreOEA")}</a>
              <Box
                className={
                  lang == "ar"
                    ? classes.dropdownAR + " dropdown1"
                    : classes.dropdownEN + " dropdown1"
                }
                style={{ textAlign: lang == "ar" ? "right" : "left" }}
              >
                <Link href="/aboutus/aboutoea" as="/aboutus/aboutoea">
                  <a className={classes.linkContent} onClick={handleClose}>
                    {t("aboutOEA")}
                  </a>
                </Link>
                <Link href="/aboutus/boardmembers" as="/aboutus/boardmembers">
                  <a className={classes.linkContent} onClick={handleClose}>
                    {t("boardOfMembers")}
                  </a>
                </Link>
                <Link
                  href="/aboutus/organizationchart"
                  as="/aboutus/organizationchart"
                >
                  <a className={classes.linkContent} onClick={handleClose}>
                    {t("organizationChart")}
                  </a>
                </Link>
                <Link href="/aboutus/branches" as="/aboutus/branches">
                  <a className={classes.linkContent} onClick={handleClose}>
                    {t("branches")}
                  </a>
                </Link>
                <Link href="/aboutus/committees" as="/aboutus/committees">
                  <a className={classes.linkContent} onClick={handleClose}>
                    {t("associations")}
                  </a>
                </Link>
              </Box>
            </Box>
            <Box className={classes.divNavLink}>
              <a className={classes.navlinkList}>{t("services")}</a>
              <Box
                className={
                  lang == "ar"
                    ? classes.dropdownAR + " dropdown2"
                    : classes.dropdownEN + " dropdown2"
                }
                style={{ textAlign: lang == "ar" ? "right" : "left" }}
              >
                <Link href="/trainings/[...params]" as="/trainings/1">
                  <a className={classes.linkContent} onClick={handleClose}>
                    {t("trainings")}
                  </a>
                </Link>
                <Link href="/events/[...params]" as="/events/1">
                  <a className={classes.linkContent} onClick={handleClose}>
                    {t("events")}
                  </a>
                </Link>
                <Link href="/gallery/[page]" as="/gallery/1">
                  <a className={classes.linkContent} onClick={handleClose}>
                    {t("gallery")}
                  </a>
                </Link>
                <Link href="/laws" as="/laws">
                  <a className={classes.linkContent} onClick={handleClose}>
                    {t("lawsAndRegulations")}
                  </a>
                </Link>
                <Link
                  href="/technicalOfficePubs/[category]"
                  as="/technicalOfficePubs/all"
                >
                  <a className={classes.linkContent} onClick={handleClose}>
                    {t("technicalOfficePubs")}
                  </a>
                </Link>
              </Box>
            </Box>
            <Box className={classes.divNavLink}>
              <Link href="/news/[...params]" as="/news/1">
                <a className={classes.navlink} onClick={handleClose}>
                  {t("news")}
                </a>
              </Link>
            </Box>
            <Box className={classes.divNavLink}>
              <Link href="/engineerszone" as="/engineerszone">
                <a className={classes.navlink} onClick={handleClose}>
                  {t("engZone")}
                </a>
              </Link>
            </Box>
            <Box className={classes.divNavLink}>
                            <Link href='/eorder' as='/eorder'>
                                <a className={classes.navlink} onClick={handleClose}>
                                    {t('eorder')}
                                </a>
                            </Link>
                        </Box>

            {user && user.authToken && user._id ? (
              <Box className={classes.logoutDivLink} onClick={signout}>
                <FontAwesomeIcon icon={faSignOutAlt} />
                <Box className={classes.divNavLink} style={{ padding: 0 }}>
                  <a className={classes.navlink} style={{ padding: "0px 5px" }}>
                    {t("logout")}
                  </a>
                </Box>
              </Box>
            ) : null}
          </Box>
        )}

        <Box className={classes.icons}>
          <a onClick={() => setIsOpen(!isOpen)} href="#" aria-label="header">
            {isOpen ? (
              <ClearAll className={classes.span} />
            ) : (
              <Menu className={classes.span} />
            )}
          </a>
        </Box>
      </Box>
    </Box>
  );
}
